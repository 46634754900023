<template>
  <v-card width="370">
    <v-card-text class="d-flex justify-center" font="12">
      <v-container class="pa-0">
        <v-row no-gutters style="height:50px">
          <!-- letter -->
          <v-col cols="2">
            <v-avatar @click="updatePile" class="letter elevation-4" tile :color="letterColor()" size="35">{{ pile.letter.toUpperCase() }}</v-avatar>
          </v-col>

          <!-- local unit / project -->
          <v-col cols="8" align-self="center">
            <div align="center">
              <span class="pile-info">{{ pileLocalUnitProjectName }}</span>
            </div>
          </v-col>

          <!-- certificate -->
          <v-col cols="2">
            <!-- direct download of certificate -->
            <v-btn v-if="pile.pileLocalUnitProjects.length === 1" absolute right :disabled="!isCertified" icon :href="certificateFile" :download="certificateName"
              ><v-avatar size="35" tile
                ><v-icon color="red" x-large>{{ icons.mdiFilePdfOutline }}</v-icon>
              </v-avatar>
            </v-btn>
            
            <!-- button to open pop-up when more than 1 certificate -->
            <v-btn v-else absolute right :disabled="!isCertified" icon @click="pile => $emit('openCertificateDialog', pile.pileLocalUnitProjects)">
              <v-avatar size="35" tile
                ><v-icon color="red" x-large>{{ icons.mdiFilePdfOutline }}</v-icon>
              </v-avatar>
            </v-btn>
          </v-col>
        </v-row>

        <!-- icon/chart -->
        <v-row no-gutters style="height: 160px">
          <v-col cols="12" align="center" class="d-flex justify-center align-center">
            <router-link :to="pileMovements">
              <v-icon v-if="pile.type.type !== 'T800' && isCertified" size="180" position="center">$pileGreen</v-icon>
              <v-icon v-else-if="pile.type.type !== 'T800' && !isCertified" size="180">$pileRed</v-icon>

              <div v-else>
                <PieChart :pile="pile" />
              </div>
            </router-link>
          </v-col>
        </v-row>

        <!-- pile's counters -->
        <v-row no-gutters class="pile-first-row mt-2">
          <v-col cols="4"><span class="mr-4 pile-info">Totale (tons)</span></v-col>
          <v-col cols="2">{{ toFloat(pile.totalTons) }}</v-col>
          <v-col cols="4"><span class="mr-4 pile-info">Max (tons)</span></v-col>
          <v-col v-if="pile.maxTons" cols="2">{{ toFloat(pile.maxTons) }}</v-col>
          <v-col v-else cols="2" style="font-size:2em"> &infin; </v-col>
        </v-row>
        <v-row no-gutters class="pile-second-row">
          <v-col cols="4"><span class="mr-4 pile-info">Carico (tons)</span></v-col>
          <v-col cols="2">{{ toFloat(pile.loadedTons) }}</v-col>
          <v-col cols="4"><span class="mr-4 pile-info">Scarico (tons)</span></v-col>
          <v-col cols="2">{{ toFloat(pile.unloadedTons) }}</v-col>
        </v-row>

        <!-- card actions -->
        <v-row no-gutters class="mt-3" justify="space-between">
          <!-- create load button -->
          <v-btn class="white--text" depressed color="main" :disabled="isLoadDisabled" @click="goToPileMovementsdetailLoad">{{ translations.load }}</v-btn>

          <!-- delete/deactivate icon -->
          <v-icon v-if="pile.totalTons + pile.loadedTons + pile.unloadedTons === 0" color="error lighten-1" class="mr-2" @click="pile => $emit('confirmDeletion', pile.id)">{{ icons.mdiDelete }}</v-icon>
          <v-icon v-else color="grey darken-2" class="mr-2" @click="pile => $emit('confirmDeactivation', pile.id)">{{ icons.mdiLock }}</v-icon>

          <!-- unload/close button -->
          <v-btn v-if="canClose(pile)" class="white--text" depressed color="main" :disabled="pile.totalTons === 0" @click="closePile(pile)">{{ translations.closePile }}</v-btn>
          <v-btn v-else class="white--text" depressed color="main" :disabled="unloadDisabled" @click="goToPileMovementsdetailUnload">{{ translations.unload }}</v-btn>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { translations } from "@/utils/common";
import { mdiFilePdfOutline, mdiDelete, mdiLock } from "@mdi/js";

// components
import PieChart from "./Charts/PieChart.vue";

// queries
import closePileMutation from "../graphql/ClosePile.gql";
import certificate from "../graphql/Certificate.gql";

export default {
  name: "PileCard",

  components: {
    PieChart
  },

  props: {
    pile: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    pileMovements: {},
    certificateFile: {},
    certificateName: "",
    certificateDialog: false
  }),

  async created() {
    this.pileMovements = {
      path: "/pile-movements",
      query: {
        pileId: this.pile.id,
        op: ""
      }
    };

    await this.downloadCertificates();
  },

  async updated() {
    await this.downloadCertificates();
  },

  methods: {
    letterColor() {
      if (["STANDARD", "SPECIAL", "SPECIALv2"].includes(this.pile.type.type)) {
        return "main";
      } else {
        return "#ffcc00";
      }
    },

    updatePile() {
      this.$emit("updatePile", this.pile);
    },

    async downloadCertificates() {
      for (let item of this.pile.pileLocalUnitProjects) {
        if (item.certificate) {
          const res = await this.$apollo.query({
            query: certificate,
            variables: {
              pileLocalUnitProjectId: item.id
            },
            fetchPolicy: "network-only"
          });

          if (this.pile.pileLocalUnitProjects.length === 1) {
            this.certificateFile = `data:application/pdf;base64,${res.data.certificate.certificate}`;
            this.certificateName = res.data.certificate.filename;
          } else {
            item['certificateBase64'] = `data:application/pdf;base64,${res.data.certificate.certificate}`;
            item['certificateName'] = res.data.certificate.filename;
          }
        }
      }
    },

    goToPileMovementsdetailUnload() {
      this.pileMovements.query.op = "unload";
      this.$router.push(this.pileMovements);
    },

    goToPileMovementsdetailLoad() {
      this.pileMovements.query.op = "load";
      this.$router.push(this.pileMovements);
    },

    toFloat(value) {
      return parseFloat(value).toFixed(2);
    },

    async closePile(pile) {
      try {
        await this.$apollo.mutate({
          mutation: closePileMutation,
          variables: {
            pileId: pile.id
          }
        });
        pile.closed = true;
      } catch (error) {
        console.error(error);
      }
    },

    canClose(pile) {
      return pile.type.type === "T800" && !pile.closed;
    }
  },

  computed: {
    translations: () => translations,

    icons: () => ({
      mdiFilePdfOutline,
      mdiDelete,
      mdiLock
    }),

    isCertified() {
      return this.pile.pileLocalUnitProjects.filter(e => e.certificate).length ? true : false;
    },

    isLoadDisabled() {
      return this.pile?.type?.type === "T800" && this.pile?.closed;
    },

    pileLocalUnitProjectName() {
      let name = "";
      if (this.pile.type.type !== "T800") {
        let index = 0;
        for (let pileLocalUnitProject of this.pile.pileLocalUnitProjects) {
          if (index > 5) {
            name = name + ' ...';
            return name;
          }
          if (pileLocalUnitProject.localUnit) {
            name = name + (name ? " - " : "") + pileLocalUnitProject.localUnit.name
          } else if (pileLocalUnitProject.project) {
            name = name + (name ? " - " : "") + (pileLocalUnitProject.project.keyCode)
          }
          index++
        }
      }
      return name;
    },

    unloadDisabled() {
      return !this.pile.pileLocalUnitProjects.filter(i => i.certificate || i.document).length || this.pile.loadedTons === 0;
    }
  }
};
</script>

<style scoped>
.letter {
  color: white;
  cursor: pointer;
}

.pile-info {
  color: #005792;
}

.pile-first-row {
  font-size: small;
}

.pile-second-row {
  font-size: x-small;
}

/* * {
  border: 1px dashed;
} */
</style>
