<template>
  <main class="ma-4">
    <!-- title -->
    <simple-date-header :date="translations.piles + ' - ' + plant.name" :goTo="previous"></simple-date-header>

    <!-- piles -->
    <transition-group tag="div" class="grid-pile-section" name="grid-pile-section">
      <div class="d-inline-flex mt-5" v-for="pile in piles" :key="pile.id">
        <pile-card
          style="width: 100%"
          :pile="pile" class="mr-2"
          @confirmDeletion="confirmDeletion(pile.id)"
          @confirmDeactivation="confirmDeactivation(pile.id)"
          @updatePile="updatePile(pile)"
          @openCertificateDialog="openCertificateDialog(pile.pileLocalUnitProjects)">
        </pile-card>
      </div>
    </transition-group>

    <!-- floating menu -->
    <v-speed-dial v-model="fab" :absolute="menu.absolute" :right="menu.right" :bottom="menu.bottom" direction="top" :open-on-hover="menu.openOnHover" transition="slide-y-reverse-transition">
      <template v-slot:activator>
        <v-btn color="main" v-model="fab" dark fab>
          <v-icon v-if="fab">{{ icons.mdiClose }}</v-icon>
          <v-icon v-else>{{ icons.mdiDotsVertical }}</v-icon>
        </v-btn>
      </template>

      <v-btn @click="openSaveNewPileDialog()" fab small dark color="main">
        <v-icon>{{ icons.mdiPlus }} </v-icon>
      </v-btn>

      <v-btn @click="printReport()" fab small dark color="main">
        <v-icon>{{ icons.mdiPrinter }}</v-icon>
      </v-btn>
    </v-speed-dial>

    <!-- save/update dialog -->
    <v-dialog v-model="dialog" max-width="900px" @click:outside="closeDialog(pile)" @keydown.enter="disabled(pile) ? null : savePile(pile)">
      <v-card class="pa-2">
        <v-card-title>
          <v-row class="ma-2" justify="center">
            <h3 class="main--text">
              {{ dialogTitle }}
            </h3>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row align="center">
            <!-- icon -->
            <v-col cols="3">
              <v-icon v-if="pile.type !== 'T800'" size="200">$pile</v-icon>
              <v-icon v-else size="200">$pieChart</v-icon>
            </v-col>

            <v-col cols="9">
              <!-- type -->
              <v-row v-if="!isUpdate">
                <v-col cols="3">
                  <v-subheader>{{ translations.typology }}</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-btn-toggle v-model="pile.type" tile group mandatory>
                    <v-btn v-for="(value, key) in pileTypes" :key="key" :value="value">
                      {{ translations[value.toLowerCase()] }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <!-- letter -->
              <v-row>
                <v-col cols="3">
                  <v-subheader> {{ translations.idLetter }}</v-subheader>
                </v-col>
                <v-col cols="2">
                  <v-text-field ref="letter" v-model="pile.letter" outlined hide-details dense :rules="rules" counter maxlength="1"> </v-text-field>
                </v-col>
                <v-col v-if="['SPECIAL', 'SPECIALv2'].includes(pile.type) && ddt" offset="4" cols="3">
                  <v-switch class="ma-0 pt-3" v-model="pile.isDefault" :label="translations.default" hide-details> </v-switch>
                </v-col>
              </v-row>

              <!-- description -->
              <v-row>
                <v-col cols="3">
                  <v-subheader>{{ translations.description }}</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field v-model="pile.description" outlined hide-details dense></v-text-field>
                </v-col>
              </v-row>

              <!-- manufacturer -->
              <v-row v-if="['STANDARD', 'SPECIAL'].includes(pile.type)">
                <v-col cols="3">
                  <v-subheader>{{ translations.manufacturer }}</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-autocomplete
                    ref="manufacturer"
                    v-model="pile.manufacturer"
                    :search-input.sync="manufacturer.params.manufacturerName"
                    :items="manufacturer.list"
                    item-text="name"
                    :loading="$apollo.queries.manufacturers.loading"
                    :disabled="pile.type === 'T800'"
                    dense
                    solo
                    hide-details
                    return-object
                    clearable
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.description }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>

                    <template v-slot:append-item>
                      <v-row v-if="manufacturer.next && !$apollo.queries.manufacturers.loading" v-intersect="onIntersect" id="manufacturer" class="ma-0" justify="center">
                        <v-progress-circular indeterminate color="main" :size="20"></v-progress-circular>
                      </v-row>
                    </template>

                    <template v-slot:no-data>
                      <v-list-item>
                        {{ translations.noManufacturerFoundWThatName }}
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <!-- local unit / project -->
              <v-row v-if="['STANDARD', 'SPECIAL', 'T800'].includes(pile.type)">
                <v-col cols="3">
                  <v-subheader>{{ ['SPECIAL', 'SPECIALv2'].includes(pile.type) ? translations.localUnitProject : translations.localUnit }}</v-subheader>
                </v-col>
                <v-col cols="9">
                  <div v-for="(pileLocalUnitProject, index) in pile.pileLocalUnitProjects" :key="index">
                    <pile-local-unit-project
                      ref="pileLocalUnitProjectComponent"
                      :pileLocalUnitProject="pileLocalUnitProject"
                      :activeMenuList="activeMenuList"
                      :projectOptions="project"
                      :localUnitOptions="localUnit"
                      :isUpdate="isUpdate"
                      :pileType="pile.type"
                      :pileClosed="pile.closed"
                      :isLast="isLast(pile)"
                      @set-active-menu-list="value => (activeMenuList = value)"
                      @fetch-more="value => {fetchMore(value)}"
                      @refetch="value => {refetch(value)}"
                      @remove="clearPileLocalUnitProject(pile, index)"
                      @add="addPileLocalUnitProject(pile)"
                    >
                    </pile-local-unit-project>
                  </div>
                </v-col>
              </v-row>

              <!-- add PileLocalUnitProject button -->
              <v-row v-if="pile.type === 'SPECIAL'" class="text-center" no-gutters>
                <v-col cols="3">
                </v-col>
                <v-col cols="9">
                  <v-btn icon @click="addPileLocalUnitProject(pile)">
                    <v-icon color="main">
                      {{ icons.mdiPlusCircle }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <!-- destination -->
              <v-row>
                <v-col cols="3">
                  <v-subheader>{{ translations.plantDestination }}</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-select v-model="pile.plant" item-text="name" :disabled="true" :placeholder="pile.plant.name" dense solo hide-details return-object></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- actions -->
        <v-card-actions class="d-flex justify-center mt-4">
          <v-btn color="main" class="white--text mr-10" depressed :disabled="disabled(pile)" @click="savePile(pile)">
            {{ isUpdate ? translations.updatePile : translations.saveNewPile }}
          </v-btn>
          <v-btn color="#ffcc00" depressed @click="closeDialog(pile)">
            {{ translations.close }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- confirmation dialog -->
    <v-dialog v-model="confirmationDialog" max-width="300px" @click:outside="closeConfirmationDialog">
      <v-card>
        <v-card-title>
          <v-row class="ma-2" justify="center">
            <h3 class="main--text">
              {{ pileToDeactivate ? translations.confirmDeactivation : translations.confirmDeletion }}
            </h3>
          </v-row>
        </v-card-title>

        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn class="white--text" color="main" @click="pileToDeactivate ? openSaveNewPileDialog() : deletePile(), (confirmationDialog = false)">
            {{ translations.confirm }}
          </v-btn>
          <v-btn class="white--text" color="main" @click="closeConfirmationDialog()">
            {{ translations.close }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- certificate dialog -->
    <certificate-dialog
      :open="showCertificateDialog"
      :pileLocalUnitProjects="pileLocalUnitProjects"
      @close-certificate-dialog="closeCertificateDialog()">
    </certificate-dialog>
  </main>
</template>
<script>
import { translations } from "@/utils/common";
import { mdiFilePdfOutline, mdiClose, mdiDotsVertical, mdiPlus, mdiPrinter, mdiPlusCircle } from "@mdi/js";
import { MomentMixin } from "@/utils/mixins/MomentMixin.js";
import { getPileTypes, getMobileDriverShowDDTButton } from "@/utils/api/config.js";
import { cloneDeep } from "lodash";
import debounce from "lodash/debounce";

// components
import SimpleDateHeader from "@/components/SimpleDateHeader.vue";
import PileCard from "../components/PileCard.vue";
import PileLocalUnitProject from "../components/PileLocalUnitProject.vue"
import CertificateDialog from "@/components/CertificateDialog.vue";

// queries
import Piles from "../graphql/Piles.gql";
import ManufacturersQuery from "../graphql/ManufacturersAndCarriers.gql";
import LocalUnitsQuery from "../graphql/LocalUnits.gql";
import ProjectsQuery from "../graphql/Projects.gql";
import savePile from "../graphql/SavePile.gql";
import saveCertificate from "../graphql/SaveCertificate.gql";
import Plant from "../graphql/Plant.gql";
import DeactivatePile from "../graphql/DeactivatePile.gql";
import DeletePile from "../graphql/DeletePile.gql";

import reportsApi from "@/utils/api/reportsApi";

export default {
  components: {
    SimpleDateHeader,
    PileCard,
    PileLocalUnitProject,
    CertificateDialog
  },

  apollo: {
    piles: {
      query: Piles,
      variables() {
        return {
          plantId: this.$route?.query?.plantId,
          page: this.page,
          pageSize: 100 // FIXME
        };
      },
      fetchPolicy: "network-only",
      result({ data }) {
        this.piles = data.piles.content;
      }
    },

    manufacturers: {
      query: ManufacturersQuery,
      variables() {
        return {
          page: 1,
          pageSize: this.pageSize,
          sort: "name",
          order: "asc",
          manufacturerName: this.manufacturer.params.manufacturerName
        };
      },
      skip() {
        return this.$route?.query?.id ? true : false;
      }
    },

    localUnits: {
      query: LocalUnitsQuery,
      variables() {
        return {
          page: 1,
          pageSize: this.pageSize,
          sort: "name",
          order: "asc",
          localUnitName: this.localUnit.params.localUnitName
        };
      },
      skip() {
        return this.$route?.query?.id ? true : false;
      }
    },

    projects: {
      query: ProjectsQuery,
      variables() {
        return {
          page: 1,
          pageSize: this.pageSize,
          sort: "keyCode",
          order: "asc",
          activeStatus: true,
          fetchCategory: false,
          fetchNonCategory: true,
          dateStr: this.currentDate ? this.formatDate(this.currentDate) : null,
          filter: this.project.params.filter
        };
      },
      skip() {
        return this.$route?.query?.id || !this.currentDate ? true : false;
      }
    }
  },

  data: () => ({
    plant: {},
    piles: [],
    dialog: false,
    dialogTitle: "",
    page: 1,
    pageSize: 10,
    rules: [v => v.length <= 1 || "massimo un carattere"],
    pile: {
      id: null,
      description: "",
      type: "STANDARD",
      letter: "",
      manufacturer: {},
      plant: {},
      pileLocalUnitProjects: [{
        id: null,
        pile: null,
        localUnit: null,
        project: null,
        part: null,
        certificate: false,
        certificateData: [],
        certificateBase64: null
      }]
    },
    manufacturer: {
      list: [],
      page: 1,
      next: true,
      query: ManufacturersQuery,
      sort: "name",
      params: {
        manufacturerName: null
      }
    },
    localUnit: {
      list: [],
      page: 1,
      next: true,
      query: LocalUnitsQuery,
      sort: "name",
      loading: false,
      params: {
        localUnitName: null
      }
    },
    project: {
      list: [],
      page: 1,
      next: true,
      query: ProjectsQuery,
      sort: "keyCode",
      loading: false,
      params: {
        activeStatus: true,
        fetchCategory: false,
        fetchNonCategory: true,
        dateStr: null,
        filter: null
      }
    },
    isUpdate: false,
    fab: false,
    menu: {
      absolute: true,
      bottom: true,
      right: true,
      openOnHover: true
    },
    previous: {
      path: "/plants"
    },
    confirmationDialog: false,
    pileToDeactivate: null,
    pileToDelete: null,
    currentDate: null,
    pileTypes: [],
    showCertificateDialog: false,
    pileLocalUnitProjects: [],
    activeMenuList: "localUnits",
    pileLocalUnitProjectSource: []
  }),

  async created() {
    const res = await this.$apollo.query({
      query: Plant,
      variables: {
        plantId: this.$route?.query?.plantId
      }
    });
    this.plant = res.data.getPlant;
    this.currentDate = this.getSimpleDay().substr(0, 10);
    this.project.params.dateStr = this.currentDate;
    this.pileTypes = getPileTypes();
    this.ddt = getMobileDriverShowDDTButton();
  },

  mixins: [MomentMixin],

  methods: {
    updatePile(pile) {
      this.pileLocalUnitProjectSource = cloneDeep(pile.pileLocalUnitProjects);
      this.dialog = true;
      this.dialogTitle = this.translations.updatePile;
      this.pile.id = pile.id;
      this.pile.type = pile.type.type;
      this.pile.description = pile.description;
      this.pile.letter = pile.letter;
      this.pile.manufacturer = pile.manufacturer;
      this.pile.pileLocalUnitProjects = cloneDeep(pile.pileLocalUnitProjects);
      this.pile.plant = this.plant;
      this.pile.closed = pile.closed;
      this.pile.isDefault = pile.isDefault;
      this.isUpdate = true;

      // select current manufacturer
      if (pile.manufacturer) {
        setTimeout(() => {
          this.$refs.manufacturer.focus();
          this.manufacturer.params.manufacturerName = pile.manufacturer.name;
        }, 200);
      }

      // focus on first input field
      this.focusOnLetter();
    },

    openSaveNewPileDialog() {
      this.dialog = true;
      this.dialogTitle = this.translations.newPile;
      this.pile.id = null;
      this.pile.description = "";
      this.pile.type = "STANDARD";
      this.pile.letter = "";
      this.pile.manufacturer = "";
      this.pile.pileLocalUnitProjects = [{
        id: null,
        pile: null,
        localUnit: null,
        project: null,
        part: null,
        certificate: false,
        document: false,
        certificateData: null,
        certificateBase64: null
      }];
      this.pile.plant = this.plant;
      this.pile.isDefault = false;

      // focus on first input field
      this.focusOnLetter();
    },

    disabled(pile) {
      if (!["T800", "SPECIALv2"].includes(pile.type)) {
      return !pile.description || !pile.type || !pile.letter || !pile.manufacturer || !pile.pileLocalUnitProjects.every(p => p.localUnit || p.project) || !pile.plant;
      } else {
        return !pile.description || !pile.type || !pile.letter || !pile.plant;
      }
    },

    closeDialog(pile) {
      this.dialog = false;
      // reset pile data
      if (this.isUpdate && pile?.id) {
        let pileInList = this.piles.find(p => p.id === pile.id)
        if (pileInList) {
          pileInList.pileLocalUnitProjects = this.pileLocalUnitProjectSource;
        }
      }
      this.isUpdate = false;
      this.pileLocalUnitProjectSource = [];
      this.activeMenuList = 'localUnits'
      this.closeConfirmationDialog();
    },

    async savePile(pile) {
      let certificateList = cloneDeep(pile.pileLocalUnitProjects);

      // clean pileLocalUnitProjects list
      pile.pileLocalUnitProjects = pile.pileLocalUnitProjects.map(e => ({
        id: e.id,
        pile: e.pile ? {id: e.pile.id} : null,
        localUnit: e.localUnit ? {id: e.localUnit.id} : null,
        project: e.project ? {id: e.project.id} : null,
        part: e.part ? {id: e.part.id} : null,
        certificate: e.certificate,
        document: e.document
      }))

      await this.$apollo
        .mutate({
          mutation: savePile,
          variables: { pileDTO: {
            id: pile.id,
            letter: pile.letter,
            description: pile.description,
            type: pile.type,
            plant: {id: pile.plant.id},
            manufacturer: pile.manufacturer ? {id: pile.manufacturer.id} : null,
            pileLocalUnitProjects: pile.type === 'SPECIALv2' && !this.isUpdate ? null : pile.pileLocalUnitProjects,
            isDefault: pile.isDefault
          } }
        })
        .then(async res => {
          this.dialog = false;
          this.isUpdate = false;
          const newPile = res.data.savePile;

          // save certificates
          for (let item of certificateList) {
            if (item.certificateData) {
              let pileLocalUnitProject = null;
              if (newPile.type.type === 'T800') {
                pileLocalUnitProject = item;
                pileLocalUnitProject.id = newPile.pileLocalUnitProjects[0].id;
              }
              for (let p of newPile.pileLocalUnitProjects) {
                if (item.localUnit && p.localUnit && item.localUnit.id === p.localUnit.id) {
                  pileLocalUnitProject = item;
                  pileLocalUnitProject.id = p.id;
                } else if ((item.project && p.project && item.project.id === p.project.id)
                  && ((!item.part && !p.part) || (item.part && p.part && item.part.id === p.part.id))) {
                  pileLocalUnitProject = item;
                  pileLocalUnitProject.id = p.id;
                }
              }
              await this.sendCertificate(newPile.id, pileLocalUnitProject);
            }
          }

          // check if deactivating old pile
          if (this.pileToDeactivate) {
            await this.deactivatePile(newPile.id);
          }

          this.$apollo.queries.piles.refetch();
        })
        .catch(error => {
          console.log(error);
        });
    },

    async sendCertificate(pileId, pileLocalUnitProject) {
      const reader = new FileReader();

      reader.readAsDataURL(pileLocalUnitProject.certificateData);
      reader.onload = () => {
        pileLocalUnitProject.certificateBase64 = reader.result.substr(reader.result.indexOf(",") + 1);

        this.$apollo
          .mutate({
            mutation: saveCertificate,
            variables: {
              pileLocalUnitProjectId: pileLocalUnitProject.id,
              certificateBase64: pileLocalUnitProject.certificateBase64
            }
          })
          .then(() => {
            this.updateCertificateProp(pileId, pileLocalUnitProject.id);
          })
          .catch(error => {
            console.log(error);
          });
      };
    },

    updateCertificateProp(pileId, pileLocalUnitProjectId) {
      let index = this.piles.indexOf(this.piles.find(pile => pileId === pile.id));

      if (index !== -1) {
        let pile = this.piles[index];
        let pileLocalUnitProjectIndex = pile.pileLocalUnitProjects.indexOf(pile.pileLocalUnitProjects.find(i => i.id === pileLocalUnitProjectId));
        if (pileLocalUnitProjectIndex !== -1) {
          pile.pileLocalUnitProjects[pileLocalUnitProjectIndex].certificate = true;
        }
      }
    },

    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        await this.fetchMore(entries[0].target.id);
      }
    },

    refetch: debounce(function(entity) {
      this.fetchMore(entity, true);
    }, 850),

    async fetchMore(entity, reset = false) {
      // get entity's query options
      let options = this[entity];

      if (!reset) {
        options.page++;
      } else {
        options.page = 1;
        options.next = true;
        options.list = [];
      }

      const res = await this.$apollo.query({
        query: options.query,
        variables: {
          page: options.page,
          pageSize: this.pageSize,
          sort: options.sort,
          order: "asc",
          ...options.params
        },
        fetchPolicy: "network-only"
      });
      options.next = res.data[entity + 's'].hasNext;
      options.list.push(...res.data[entity + 's'].content);
    },

    printReport() {
      reportsApi.pileMovementsReport(null, this.plant.id);
    },

    confirmDeactivation(pileId) {
      this.pileToDeactivate = pileId;
      this.confirmationDialog = true;
    },

    closeConfirmationDialog() {
      this.pileToDeactivate = null;
      this.pileToDelete = null;
      this.confirmationDialog = false;
    },

    async deactivatePile(pileId) {
      await this.$apollo
        .mutate({
          mutation: DeactivatePile,
          variables: { pileId: this.pileToDeactivate, newPileId: pileId }
        })
        .then(() => {
          this.pileToDeactivate = null;
          this.$apollo.queries.piles.refetch();
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
          this.showErrorAlert = true;
          setTimeout(() => (this.showErrorAlert = false), 6000);
        });
    },

    async deletePile() {
      await this.$apollo
        .mutate({
          mutation: DeletePile,
          variables: { pileId: this.pileToDelete }
        })
        .then(() => {
          this.pileToDelete = null;
          this.$apollo.queries.piles.refetch();
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
          this.showErrorAlert = true;
          setTimeout(() => (this.showErrorAlert = false), 6000);
        });
    },

    confirmDeletion(pileId) {
      this.pileToDelete = pileId;
      this.confirmationDialog = true;
    },

    focusOnLetter() {
      setTimeout(() => {
        this.$refs.letter.focus();
      }, 200);
    },

    openCertificateDialog(pileLocalUnitProjects) {
      this.showCertificateDialog = true;
      this.pileLocalUnitProjects = pileLocalUnitProjects;
    },

    closeCertificateDialog() {
      this.showCertificateDialog = false;
      this.pileLocalUnitProjects = [];
    },

    clearPileLocalUnitProject(pile, pileLocalUnitProjectIndex) {
      if (pile.pileLocalUnitProjects.length === 1) {
        this.resetPileLocalUnitProjects(pile);
      } else {
        pile.pileLocalUnitProjects.splice(pileLocalUnitProjectIndex, 1);
      }
    },

    resetPileLocalUnitProjects(pile) {
      pile.pileLocalUnitProjects = [this.getEmptyPileLocalUnitProject()];
    },

    getEmptyPileLocalUnitProject() {
      return {
        id: null,
        pile: null,
        localUnit: null,
        project: null,
        part: null,
        certificate: false,
        certificateData: null,
        certificateBase64: null
      }
    },

    addPileLocalUnitProject(pile) {
      pile.pileLocalUnitProjects.push(this.getEmptyPileLocalUnitProject());
    },

    isLast(pile) {
      if (pile.type === 'SPECIAL') {
        return pile.pileLocalUnitProjects.length === 1;
      }
      return false;
    },
  },

  computed: {
    translations: () => translations,

    icons: () => ({
      mdiFilePdfOutline,
      mdiClose,
      mdiDotsVertical,
      mdiPlus,
      mdiPrinter,
      mdiPlusCircle
    }),

    manufacturerFilter() {
      return this.manufacturer.params.manufacturerName;
    },

    isCertified() {
      return this.pile.pileLocalUnitProjects.filter(p => p.certificate).length ? true : false;
    }
  },

  watch: {
    manufacturerFilter() {
      this.manufacturer.page = 1;
    },

    manufacturers() {
      this.manufacturer.list = this.manufacturers.content;
      this.manufacturer.next = this.manufacturers.hasNext;
    },

    localUnits() {
      this.localUnit.list = this.localUnits.content;
      this.localUnit.next = this.localUnits.hasNext;
    },

    projects() {
      this.project.list = this.projects.content;
      this.project.next = this.projects.hasNext;
    }
  }
};
</script>

<style scoped>
.grid-pile-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  grid-gap: 5px;
}

/* * {
  border: 1px dashed;
} */
</style>
