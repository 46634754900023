<template>
  <div>
    <apexchart type="pie" :width="width" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  name: "PieChart",

  props: {
    width: {
      type: Number,
      default: 215
    },
    pile: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: "pie",
          animations: {
            enabled: false
          }
        },
        colors: [],
        labels: [],
        legend: {
          show: false
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -10
            },
            expandOnClick: false
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function(value, { seriesIndex, w }) {
            return w.config.labels[seriesIndex] ? "T" + w.config.labels[seriesIndex] : "";
          },
          style: {
            fontWeight: 500
          },
          dropShadow: {
            enabled: true,
            top: 2,
            left: 2,
            color: "#333",
            opacity: 1
          }
        },
        tooltip: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#333"]
        },
        states: {
          hover: {
            filter: "none"
          }
        }
      }
    };
  },

  methods: {
    getPercentageOf(value, total) {
      return parseFloat(((value / total) * 100).toFixed(2));
    },

    getColors() {
      return {
        white: "#ffffff",
        green: "#009933",
        yellow: "#ffcc00",
        red: "#ff0000"
      };
    },

    getThresholds() {
      return {
        greenThreshold: parseInt(this.pile.type.greenThreshold),
        yellowThreshold: parseInt(this.pile.type.yellowThreshold),
        redThreshold: parseInt(this.pile.type.redThreshold)
      };
    },

    getSlicePercentages(totalTons) {
      let { greenThreshold, yellowThreshold, redThreshold } = this.getThresholds();
      return {
        greenSlicePercentage: this.getPercentageOf(greenThreshold, redThreshold),
        yellowSlicePercentage: this.getPercentageOf(yellowThreshold - greenThreshold, redThreshold),
        redSlicePercentage: this.getPercentageOf(redThreshold - yellowThreshold, redThreshold),
        whiteSlicePercentage: this.getPercentageOf(redThreshold - totalTons, redThreshold)
      };
    },

    getPartialSlicePercentage(value) {
      let { redThreshold } = this.getThresholds();
      return this.getPercentageOf(value, redThreshold);
    },

    buildChart() {
      let totalTons = this.pile.totalTons;
      let { white, green, yellow, red } = this.getColors();
      let { greenThreshold, yellowThreshold, redThreshold } = this.getThresholds();
      let { greenSlicePercentage, yellowSlicePercentage, redSlicePercentage, whiteSlicePercentage } = this.getSlicePercentages(totalTons);

      let colors = [];
      let series = [];
      let labels = [];

      // empty pile
      if (totalTons === 0) {
        series = [100];
        colors = [white];

        // full pile
      } else if (totalTons === redThreshold) {
        series = [greenSlicePercentage, yellowSlicePercentage, redSlicePercentage];
        colors = [green, yellow, red];
        labels = [greenThreshold, yellowThreshold, redThreshold];

        // partially filled
      } else {
        colors = [green, white];
        series = [whiteSlicePercentage];

        if (totalTons <= greenThreshold) {
          series.splice(0, 0, this.getPartialSlicePercentage(totalTons));
        } else if (totalTons > greenThreshold && totalTons <= yellowThreshold) {
          series.splice(0, 0, greenSlicePercentage, this.getPartialSlicePercentage(totalTons - greenThreshold));
          colors.splice(-1, 0, yellow);
          labels = [greenThreshold];
        } else {
          series.splice(0, 0, greenSlicePercentage, yellowSlicePercentage, this.getPartialSlicePercentage(totalTons - yellowThreshold));
          colors.splice(-1, 0, yellow, red);
          labels = [greenThreshold, yellowThreshold];
        }
      }

      this.series = series;
      this.chartOptions = { ...this.chartOptions, ...{ colors, labels } };
    }
  },

  created() {
    this.buildChart();
  },

  watch: {
    pile() {
      this.buildChart();
    }
  }
};
</script>
